.header {
  width: 100%;
  background-color: #f3f3f3;
  backdrop-filter: none;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 20;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header .logo {
  height: 42px;
  width: 42px;
}

.header .navigation-menu-item {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.header .navigation-menu-item:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.header .navigation-menu-item a {
  color: #474747;
  text-decoration: none !important;
  fill: #474747;
}

.header .active {
  background-color: #eaeaea;
  fill: #d04a02 !important;
}
