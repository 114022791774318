.root-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: auto;
}

.root-page .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.root-page .outlet {
  flex-grow: 1;
  padding: 88px 48px 48px 48px;
  width: 100%;
}

.skip-to-content {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;
}

.skip-to-content:focus {
  top: 0;
}
