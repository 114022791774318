.footer {
  padding: 1em 1em;
  background-color: white;
  z-index: 99;
  margin-top: auto;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.footer-text {
  font-size: 14px !important;
  max-width: 850px;
  text-align: left;

  a:hover {
    cursor: pointer;
  }
}

.version {
  text-align: right !important;
}

//.css-mhc70k-MuiGrid-root > .MuiGrid-item {
//  padding: 0 !important;
//  margin-top: 16px;
//  margin-left: 16px;
//}
