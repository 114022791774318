@use "../../../../assets/styles/helpers/colors.scss" as *;

.displayHeader {
  width: 100%;
  table .MuiTableHead-root {
    display: table-header-group !important;
  }
}
.first-column-cell::after {
  content: "";
  background: $color-textColor-light;
  position: absolute;
  bottom: 0; /* Adjust this value to position the line */
  left: 0;
  width: 100%; /* Set the width to cover the entire cell */
}
.first-column-cell-last::after {
  content: "";
  background: $color-primary;
  position: absolute;
  bottom: 0; /* Adjust this value to position the line */
  left: 0;
  width: 100%; /* Set the width to cover the entire cell */
}

.table-header-cell {
  > div {
    justify-content: center !important;
  }
}

.last-column {
  color: $color-primary !important;
}


.highlighted-column {
  color: $color-primary !important;
}
